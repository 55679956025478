const workOrderRoute = {
  name: 'cfa.workOrder',
  component: () => import('@/views/cfa/workOrder/WorkOrderPage.vue'),
  path: '/cfa/workOrder'
};

const overdueParcelRoute = {
  path: '/cfa/parcels/overdue',
  name: 'cfa.overdueParcels',
  component: () => import('@/views/cfa/overdueParcels/OverdueParcelsPage.vue')
};

const publishRoute = {
  path: '/cfa/publish',
  name: 'cfa.publish',
  component: () => import('@/views/cfa/punishmentTask/PublishPage.vue')
};

export default {
  name: 'cfa',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [workOrderRoute, overdueParcelRoute, publishRoute]
};
