import type { selectOption } from '@/components/FtlSelect/type';
import {
  requestClientInfo,
  requestTransferCenter,
  requestTransferLine,
  requestClientList,
  requestAirportInfo,
  requestSuppliers,
  requestShipmentLine,
  requestSenderConfig,
  channel,
  requestShipmentRouteLine,
  requestTransferSuppliers,
  requestPriceLevel,
  requestTransferClientCode
} from '@/components/FtlSelect/hooks/remote';
import {
  addressList,
  countryList,
  clearanceAirportList,
  departureCountry,
  targetSystem,
  clearanceType,
  priceStatus
} from '@/components/FtlSelect/hooks/local';

const valueMap: Map<string, selectOption> = new Map([
  ['client', requestClientInfo],
  ['transferCenter', requestTransferCenter],
  ['transferSuppliers', requestTransferSuppliers],
  ['transferLine', requestTransferLine],
  ['transferClientCode', requestTransferClientCode],
  ['country', countryList],
  ['address', addressList],
  ['clientGroup', requestClientList],
  ['airport', requestAirportInfo],
  ['clearanceAirport', clearanceAirportList],
  ['suppliers', requestSuppliers],
  ['shipment', requestShipmentLine],
  ['senderConfig', requestSenderConfig],
  ['departureCountry', departureCountry],
  ['channel', channel],
  ['targetSystem', targetSystem],
  ['requestShipmentRouteLine', requestShipmentRouteLine],
  ['clearanceType', clearanceType],
  ['priceLevel', requestPriceLevel],
  ['priceStatus', priceStatus]
]);

export { valueMap };
export { statusText, error, setClient, setMode, setPriceType } from './remote';
export type { selectOption };
