import { createI18n } from 'vue-i18n';
import oneEnLocale from '@ftlab/one-vue3/i18n/en.json';
import oneCnLocale from '@ftlab/one-vue3/i18n/cn.json';
import oneFrLocale from '@ftlab/one-vue3/i18n/fr.json';
import ssm from './ssm'
import psm from './psm'
import lms from './lms';
import clr from './clr';
import cfs from './cfs';
import cfm from './cfm';
import cfa from './cfa';
import csm from './csm';
import bms from './bms';
import npm from './npm/npm_message.json';
import sts from './sts/sts_dashboard.json';
import crm from './crm'
import common from './common'
import dcm from './dcm';

const messages: any = {
  en: {
    ...oneEnLocale
  },
  fr: {
    ...oneFrLocale
  },
  cn: {
    ...oneCnLocale,
    ...ssm,
    ...psm,
    ...lms,
    ...clr,
    ...cfs,
    ...cfm,
    ...cfa,
    ...bms,
    ...csm,
    ...sts,
    ...npm,
    ...crm,
    ...common,
    ...dcm
  }
};

const i18n = createI18n({
  legacy: false,
  locale: 'cn',
  messages,
  silentTranslationWarn: true,
  globalInjection: true,
  fallbackLocale: 'no-such-locale'
});

const globalTranslate = (...args: any[]) => (i18n.global as any).t(...args);

export { globalTranslate };

export default i18n;
