const tracePushRoute = {
  component: () => import('@/views/cfs/TracePushSetting/TracePushPage.vue'),
  name: 'cfs.tracePush',
  path: '/cfs/trace/push'
};

const clientDeliveryRoute = {
  component: () => import('@/views/cfs/ClientDeliverySetting/ClientDeliverySettingPage.vue'),
  name: 'cfs.delivery.trace',
  path: '/cfs/clientDelivery'
};

const ApiProtocolRoute = {
  component: () => import('@/views/cfs/ApiProtocol/ApiProtocolPage.vue'),
  name: 'cfs.api.protocol',
  path: '/cfs/api/protocol'
};

const ClearanceAgingSetting = {
  component: () => import('@/views/cfs/ClearanceAgingSetting/ClearanceAgingSettingPage.vue'),
  name: 'cfs.clearance.aging',
  path: '/cfs/clearanceAging'
};

export default {
  name: 'cfs',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [tracePushRoute, clientDeliveryRoute, ApiProtocolRoute, ClearanceAgingSetting]
};
