const kpiRoute = {
  path: '/cfm/kpi',
  name: 'cfm.kpi',
  component: () => import('@/views/cfs/ClearanceAgingSetting/components/Tabs/Kpi/KpiList.vue')
};

const lostTraceRoute = {
  path: '/cfm/lostTrace',
  name: 'cfm.lostTrace',
  component: () => import('@/views/cfm/lostTrace/LostTracePage.vue')
};

const tracePushMonitorRoute = {
  name: 'cfm.trace.push',
  path: '/cfm/trace/push',
  component: () => import('@/views/cfm/tracePushMonitor/TracePushMonitorPage.vue')
};

const temuFulfilmentRoute = {
  path: '/cfm/temu/fulfilment',
  name: 'cfm.temu.fulfilment',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  meta: {
    clientAppRouteName: 'temu.performanceFollow',
    clientApp: 'si'
  }
};

const hzcnTraceQuantityRoute = {
  path: '/cfm/hzcn/trace/quantity',
  name: 'cfm.hzcn.trace.quantity',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  meta: {
    clientAppRouteName: 'hzcn.hzcnTraceQuality',
    clientApp: 'si'
  }
};

const temuTraceQuantityRoute = {
  path: '/cfm/temu/trace/quantity',
  name: 'cfm.temu.trace.quantity',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  meta: {
    clientAppRouteName: 'temu.temuTraceQuality',
    clientApp: 'si'
  }
};

const temuClearanceAgingRoute = {
  path: '/cfm/temu/clearance/aging',
  name: 'cfm.temu.clearance.aging',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  meta: {
    clientAppRouteName: 'temu.clearanceAging',
    clientApp: 'si'
  }
};

const temuSpainExamineRoute = {
  path: '/cfm/temu/spain/examine',
  name: 'cfm.temu.spain.examine',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  meta: {
    clientAppRouteName: 'temu.spainExamine',
    clientApp: 'si'
  }
};

export default {
  name: 'cfm',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [
    kpiRoute,
    lostTraceRoute,
    tracePushMonitorRoute,
    temuFulfilmentRoute,
    hzcnTraceQuantityRoute,
    temuTraceQuantityRoute,
    temuClearanceAgingRoute,
    temuSpainExamineRoute
  ]
};
