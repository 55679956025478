import { App } from 'vue';
import { IconPark } from '@icon-park/vue-next/es/all';
import HiddenWord from '@/components/HiddenWord.vue';
import FtlContainer from '@/layout/components/FtlContainer.vue';
import FileUploadBtn from '@/components/FileUploadBtn.vue';
import FtlBtn from '@/components/FtlBtn.vue';
import EditTags from '@/components/EditTags.vue';
import FtlTag from '@/components/FtlTag.vue';
import FtlTable from '@/components/FtlTable.vue';
import FtlSelect from '@/components/FtlSelect/FtlSelect.vue';
import RtfEditor from '@/components/RtfEditor.vue';
import BatchEditor from '@/components/BatchEditor.vue';

type registerFunc = (app: App) => void;

const install: registerFunc = (app) => {
  [
    { name: 'FtlSelect', component: FtlSelect },
    { name: 'EditTags', component: EditTags },
    { name: 'FtlTag', component: FtlTag },
    { name: 'RtfEditor', component: RtfEditor },
    { name: 'BatchEditor', component: BatchEditor },
    { name: 'FileUploadBtn', component: FileUploadBtn },
    { name: 'FtlBtn', component: FtlBtn },
    { name: 'FtlTable', component: FtlTable },
    {
      name: 'FtlContainer',
      component: FtlContainer
    },
    { name: 'HiddenWord', component: HiddenWord },
    { name: IconPark.name, component: IconPark }
  ].forEach(({ name, component }) => app.component(name, component));
};

export default install;
