import { ref, Ref } from 'vue';
import { isObject, sortBy, uniqBy } from 'lodash-es';
import type { selectOption, resBody } from '@/components/FtlSelect/type';
import { warning } from '@/utils';
import { resource } from '@/api';
import i18n, { globalTranslate } from '@/i18n';

const statusText = ref('');
const error = ref(false);
const retryTimes = ref(0);
const client: Ref<string> = ref('');
const mode: Ref<string> = ref('');
const priceType: Ref<string> = ref('');

const requestWay: selectOption = async ({ url, labelKey, valueKey, query, translateKey, formatter }) => {
  if (error.value) retryTimes.value += 1;
  if (retryTimes.value > 5) warning('global.error.network');
  error.value = false;
  try {
    statusText.value = 'Loading...';
    const { data }: resBody = await resource.get(url, { params: query });
    const result = sortBy(
      data.map((val: Record<string, string>) =>
        isObject(val)
          ? {
              // eslint-disable-next-line no-nested-ternary
              label: translateKey
                ? globalTranslate(`${translateKey}.${val[labelKey]}`)
                : formatter
                ? formatter((val as Record<string, string>)[labelKey], (val as Record<string, string>)[valueKey])
                : (val as Record<string, string>)[labelKey],
              value: (val as Record<string, string>)[valueKey]
            }
          : {
              label: translateKey ? globalTranslate(`${translateKey}.${val}`) : val,
              value: val
            }
      ),
      'label'
    );
    retryTimes.value = 0;
    return uniqBy(result, 'value');
  } catch (e) {
    statusText.value = 'Loading Error';
    error.value = true;
    return [];
  } finally {
    statusText.value = '暂无数据';
  }
};

const requestClientInfo = () =>
  requestWay({
    url: '/oms-admin/admin/option/clients',
    labelKey: 'name',
    valueKey: 'id',
    formatter: (label: string, value: string) => `${value}-${label}`
  });

const requestTransferCenter = () => requestWay({ url: '/wms/transferCenters/array', labelKey: 'id', valueKey: 'id' });

const requestTransferLine = () =>
  requestWay({
    url: '/oms-admin/admin/psm/transferProduct/array',
    labelKey: 'name',
    valueKey: 'name',
    query: {
      filter: {
        shipment: false,
        client: client.value
      }
    }
  });

const requestTransferSuppliers = () =>
  requestWay({
    url: '/oms-admin/admin/psm/transferProduct/array',
    labelKey: 'carrierId',
    valueKey: 'carrierId',
    query: {
      filter: {
        shipment: false,
        client: client.value
      }
    }
  });

const requestTransferClientCode = () =>
  requestWay({
    url: '/oms/transferProductMappingMatch/array',
    labelKey: 'alias',
    valueKey: 'alias',
    query: {}
  });

const requestShipmentRouteLine = () =>
  requestWay({
    url: '/oms-shipment/admin/shipmentProduct/array',
    labelKey: 'routeName',
    valueKey: 'routeName',
    query: {}
  });

const requestClientList = () =>
  requestWay({
    url: '/group/client',
    labelKey: 'code',
    valueKey: 'id'
  });

const requestAirportInfo = () => requestWay({ url: '/flights/array', labelKey: 'number', valueKey: 'number' });

const requestSuppliers = () => requestWay({ url: '/wms/suppliers/array', labelKey: 'id', valueKey: 'id' });

const requestSenderConfig = () =>
  requestWay({ url: '/oms-shipment/admin/shipmentSenderAddress/getAddressLabelList', labelKey: 'id', valueKey: 'id' });

const channel = () =>
  requestWay({
    url: '/oms-admin/admin/psm/transferProduct/channel',
    labelKey: 'id',
    valueKey: 'id',
    query: { option: mode.value }
  });

const requestShipmentLine = () =>
  requestWay({
    url: '/oms-admin/admin/drs/pricings/findForSelect',
    labelKey: 'id',
    valueKey: 'id',
    query: { type: 'SHIPMENT', client: client.value, ...(mode.value ? { mode: mode.value } : {}) }
  });

const requestPriceLevel = () =>
  requestWay({
    url: `/oms-admin/admin/crm/PriceGradeSetting/getPriceGradeName/${priceType.value}`,
    labelKey: 'priceGrade',
    valueKey: 'id'
  });

const setClient = (props: string): void => {
  client.value = props;
};
const setMode = (props: string): void => {
  mode.value = props;
};

const setPriceType = (props: string): void => {
  priceType.value = props;
};

export {
  requestTransferLine,
  requestTransferCenter,
  requestTransferSuppliers,
  requestClientInfo,
  requestClientList,
  requestAirportInfo,
  requestShipmentLine,
  requestSuppliers,
  requestSenderConfig,
  channel,
  statusText,
  error,
  setClient,
  setPriceType,
  setMode,
  requestShipmentRouteLine,
  requestTransferClientCode,
  requestPriceLevel
};
