const agingDistributionRoute = {
  path: '/sim/agingDistribution',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'sim.agingDistribution',
  meta: {
    clientAppRouteName: 'allProducts.agingDistribution',
    clientApp: 'si'
  }
};

const charterRoute = {
  path: '/sim/charter',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'sim.charterFlight',
  meta: {
    clientAppRouteName: 'allProducts.charterFlight',
    clientApp: 'si'
  }
};

const clearanceRoute = {
  path: '/sim/clearance',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'sim.clearanceFrance',
  meta: {
    clientAppRouteName: 'clearance.clearanceFrance',
    clientApp: 'si'
  }
};

const forecastRoute = {
  path: '/sim/forecast',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'sim.forecast',
  meta: {
    clientAppRouteName: 'forecast.etaVolume',
    clientApp: 'si'
  }
};

const nextWeekInventoryForecastRoute = {
  path: '/sim/forecast/weekly',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'sim.forecast.weekly',
  meta: {
    clientAppRouteName: 'forecast.weekly',
    clientApp: 'si'
  }
};

const returnShipmentRoute = {
  path: '/sim/returnShipment',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'sim.returnShipment',
  meta: {
    clientAppRouteName: 'return.deliveryReturn',
    clientApp: 'si'
  }
};

const saleRoute = {
  path: '/sim/sales',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'sim.sales',
  meta: {
    clientAppRouteName: 'allProducts.sales',
    clientApp: 'si'
  }
};

const shipmentRoute = {
  path: '/sim/shipment',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'sim.shipment',
  meta: {
    clientAppRouteName: 'allProducts.deliveryOrder',
    clientApp: 'si'
  }
};

const warehouseRoute = {
  path: '/sim/warehouse',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'sim.warehouse',
  meta: {
    clientAppRouteName: 'warehouse.volumeStatistics',
    clientApp: 'si'
  }
};

export default {
  name: 'sim',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [
    agingDistributionRoute,
    charterRoute,
    clearanceRoute,
    forecastRoute,
    nextWeekInventoryForecastRoute,
    returnShipmentRoute,
    saleRoute,
    shipmentRoute,
    warehouseRoute
  ]
};
