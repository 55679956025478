const reconciliationFlowRoute = [
  {
    path: '/bms/reconciliationFlow/taskList',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'bms.process.GENERATE_BILL',
    meta: {
      clientAppRouteName: 'fcs.process.GENERATE_BILL',
      clientApp: 'bms'
    }
  },
  {
    path: '/bms/reconciliationFlow/billConfirm',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'bms.process.VERIFY_BILL',
    meta: {
      clientAppRouteName: 'fcs.process.VERIFY_BILL',
      clientApp: 'bms'
    }
  },
  {
    path: '/bms/reconciliationFlow/billCreate',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'bms.process.CREATE_INVOICE',
    meta: {
      clientAppRouteName: 'fcs.process.CREATE_INVOICE',
      clientApp: 'bms'
    }
  },
  {
    path: '/bms/reconciliationFlow/billDownload',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'bms.process.VIEW_INVOICE',
    meta: {
      clientAppRouteName: 'fcs.process.VIEW_INVOICE',
      clientApp: 'bms'
    }
  }
];

const billRoute = [
  {
    path: '/bms/bill',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'bms.bill',
    meta: {
      clientAppRouteName: 'payment.list',
      clientApp: 'bms'
    }
  },
  {
    path: '/bms/bill/create',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'bms.bill.create',
    meta: {
      clientAppRouteName: 'payment.create',
      clientApp: 'bms'
    }
  },
  {
    path: '/bms/bill/:id/edit',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'bms.bill.edit',
    meta: {
      clientAppRouteName: 'payment.edit',
      clientApp: 'bms'
    },
    props: true
  }
];

const auditRoute = {
  component: () => import('@/views/bms/audit/AuditPage.vue'),
  name: 'bms.audit',
  path: '/bms/audit'
};

export default {
  name: 'bms',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [...reconciliationFlowRoute, ...billRoute, auditRoute]
};
