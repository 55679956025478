import apiProcotol from './cfs_apiProcotol.json';
import clearanceAging from './cfs_clearanceAging.json';
import tracePush from './cfs_tracePush.json';
import clientDelivery from './cfs_clientDelivery.json';

export default {
  ...apiProcotol,
  ...clearanceAging,
  ...tracePush,
  ...clientDelivery
};
